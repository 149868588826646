import React, { useCallback, useEffect, useState, FC } from "react";
import { navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { removeCoupon, setBackToPreviousState } from "../../../store/cartSlice";
import { rootReducer } from "../../../store";
import { logout } from "../../../utils/helper";
import useJWTChecker from "../../../utils/Custom-hook/useJWTChecker";
import {useLocation} from "@reach/router";

interface Props {
  selectedOption: String;
}
const ListItems = ["My Account", "My Addresses", "My Orders", "Log Out"];

const MyAccountList: FC<Props> = ({ selectedOption }) => {
  const location = useLocation();
  const { userId } = useJWTChecker();
  const { refreshUI, coupon } = useSelector((state: rootReducer) => state.cart);
  const dispatch = useDispatch();
  const handleClick = async (item: any) => {
    // console.log(localTrackingState, "localTrackingStatelocalTrackingState")
    const localTrackingState = refreshUI ? refreshUI : false;
    dispatch(setBackToPreviousState(localTrackingState));

    if (item == "My Account") {
      console.log(item, "ELSE1")

      navigate("/my-account");
    } else if (item == "My Addresses") {


      navigate("/my-addresses"+location.search);
    } else if (item == "My Orders") {
      console.log(item, "ELSE3")

      navigate(`/my-orders`+location.search);
    } else {
      console.log(item, "ELSE4")
      try {
        await logout(userId as string);
        // CLEAR THE APPLIED COUPON AFTER LOGOUT
        if (coupon.length > 0) {
          coupon.map((cupon) => {
            dispatch(removeCoupon(cupon));
          });
        }
      } catch (error) {
        navigate("/login"+location.search);
      }

      localStorage.removeItem("userAccountDetails");
      localStorage.removeItem("user");
      navigate("/login"+location.search);
    }
    console.log("============")
  };

  return (
    <div className="md:w-1/4 w-full mt-4">
      <ul className="list-none mb-10 flex flex-col gap-2">
        {ListItems?.length > 0 &&
          ListItems.map((item, index) => (
            <div
              key={index}
              onClick={() => handleClick(item)}
              className={`cursor-pointer p-3 border rounded-[12px] font-sofiaRegular font-regular ${
                selectedOption === item ? "bg-black text-white" : "bg-white"
              }`}
            >
              <li>{item}</li>
            </div>
          ))}
      </ul>
    </div>
  );
};

export default MyAccountList;
