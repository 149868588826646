import React, { useState, FC, useEffect } from "react";
import TextInput from "../common/InputFields/TextInput";
import { useFormik } from "formik";
import passwordSchema from "../../utils/ValidationSchema/passwordValidation";
import Button from "../common/Buttons/Button";
import { ArrowLeft, CloseIcon } from "../common/Svg";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import axios from "axios";
import { toast } from "react-toastify";
import { updateAccountDetails, tokenDeletion } from "../../services/woocommerceServices/Customer";
import { navigate } from "gatsby";
import {useLocation} from "@reach/router";

interface Props {
  onSuccessUpdatePassword: Function;
  onPressCancelBtn: Function;
}

const EditPassword: FC<Props> = ({
  onSuccessUpdatePassword,
  onPressCancelBtn,
}) => {
  const location = useLocation();

  const { isLoggedIn, userId, email } = useJWTChecker();
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      const { oldpassword, newpassword, confirmpassword } = values;

      if (email) {
        setButtonLoader(true);
        let getResponse = await checkOldPassword(values.oldpassword);

        if (getResponse.status) {
          if (oldpassword == newpassword) {
            setButtonLoader(false);
            formik.setFieldError(
              "newpassword",
              "New password should not be same"
            );
            return;
          } else {
            handleEditPassword(confirmpassword);
          }
        } else {
          setButtonLoader(false);
          formik.setFieldError("oldpassword", "Incorrect old password");
          return;
        }
      }
    },
  });

  const checkOldPassword = async (oldPassword: any) => {
    const TOKEN_URL = process.env.GATSBY_JWT_API_AUTH_TOKEN as string;
    try {
      let data = {
        username: email,
        password: oldPassword,
      };
      const response = await axios.post(TOKEN_URL, data);
      if (response.status === 200) {
        return { status: true, oldPassword: oldPassword };
      } else {
        return { status: false, oldPassword: oldPassword };
      }
    } catch (error) {
      return { status: false, oldPassword: oldPassword };
    }
  };

  const handleEditPassword = async (data: any) => {

    if (userId) {
      let updatedPassword: any = {
        password: data,
      };

      const getUpdateResponse: any = await updateAccountDetails(
        userId,
        updatedPassword
      );

      if (getUpdateResponse?.data) {
        const tokenDelete: any = await tokenDeletion(userId);
        toast.success("Password updated successfully.");
        onSuccessUpdatePassword();
        localStorage.removeItem("userAccountDetails");
        localStorage.removeItem("user");
        setTimeout(() => {
          navigate('/login' + location.search);
        }, 2000);
        // if(tokenDelete.data.status === 200)
        // {

        // }
        // else
        // {
        //   toast.error("error, please try again later!");
        //   setButtonLoader(false);
        // }
        // console.log(tokenDelete);

      } else {
        toast.error("error, please try again later!");
        setButtonLoader(false);
      }
    }
    setButtonLoader(false);
  };

  return (
    <div className="max-w-full bg-white border rounded-[12px] overflow-hidden">
      <div className="mb-2 py-3 px-3 flex justify-between items-center bg-bg-grey border-b">
        <h2 className="text-[20px] font-sofiaSemibold font-semiBold text-black  text-center">
          Edit Password
        </h2>
        <button
          onClick={() => {
            onPressCancelBtn();
          }}
          className="flex items-center w-8 h-8 text-gray-500 hover:text-gray-700 text-xl"
        >
          <CloseIcon />

        </button>
      </div>

      <div className="px-3 py-3">
        <form name="editPasswordForm" onSubmit={formik.handleSubmit} noValidate>
          <div className="w-full">
            <div className="w-full flex flex-col relative font-sofiaRegular font-regular">
              <TextInput
                type={"password"}
                name={"oldpassword"}
                id={"oldpassword"}
                label={"Old Password"}
                placeholder={"Enter old Password"}
                className={"w-full input mt-4"}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldpassword}
              />
              {formik.errors.oldpassword && formik?.touched.oldpassword ? (
                <div className="error-msg md:!top-[2px] !top-[4px]">{formik.errors.oldpassword}</div>
              ) : null}
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="w-full flex flex-col relative  font-sofiaRegular font-regular">
              <TextInput
                type={"password"}
                name={"newpassword"}
                id={"newpassword"}
                label={"New Password"}
                placeholder={"Enter new Password"}
                className={"w-full input mt-4"}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newpassword}
              />
              {formik.errors.newpassword && formik?.touched.newpassword ? (
                <div className="error-msg md:!top-[2px] !top-[4px]">{formik.errors.newpassword}</div>
              ) : null}
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="w-full flex flex-col relative  font-sofiaRegular font-regular">
              <TextInput
                type={"password"}
                name={"confirmpassword"}
                id={"confirmpassword"}
                label={"Confirm Password"}
                placeholder={"Confirm your Password"}
                className={"w-full input mt-4"}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmpassword}
              />
              {formik.errors.confirmpassword &&
                formik?.touched.confirmpassword ? (
                <div className="error-msg md:!top-[2px] !top-[4px]">
                  {formik.errors.confirmpassword}
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4 justify-end mt-8 mb-6">
            <Button
              type="submit"
              text="Save"
              className="submit-btn hover:bg-black bg-primary text-[20px] font-sofiaMedium font-medium w-full  py-3 text-white rounded-[35px]   transition-all ease-in "
              loading={buttonLoader}
              disabled={buttonLoader}
            />

            <Button
              onClick={() => {
                onPressCancelBtn();
              }}
              type="submit"
              text="Cancel"
              className="submit-btn !bg-bg-grey hover:!bg-black text-[20px] font-sofiaMedium font-medium w-full  py-3 !text-black hover:!text-white border rounded-[35px] transition-all ease-in "
            />
          </div>
        </form>
      </div>


    </div>

  );
};
export default EditPassword;
